@tailwind base;
@tailwind components;
@tailwind utilities;

// @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

:root {
  --theme-color-1: white;
  --theme-color-2: #77b339;
  --theme-color-3: #4d4e50;
  --theme-color-4: #000000;
  --theme-color-5: #e6e6e6;
}

.theme-c1 {
  color: var(--theme-color-1);
}

.theme-c2 {
  color: var(--theme-color-2);
}

.theme-c3 {
  color: var(--theme-color-3);
}

.theme-c4 {
  color: var(--theme-color-4);
}

.theme-c5 { color:var(--theme-color-5); }

.theme-b1 {
  background-color: var(--theme-color-1);
}

.theme-b2 {
  background-color: var(--theme-color-2);
}

.theme-b3 {
  background-color: var(--theme-color-3);
}

.theme-b4 {
  background-color: var(--theme-color-4);
}

.theme-b5 { background-color:var(--theme-color-5); }

html,
body {
  font-family: 'Raleway', sans-serif;
  @apply font-normal overflow-x-hidden text-base p-0 m-0 antialiased theme-b4 theme-c4;

  &.no-scroll {
    @apply overflow-hidden;
  }
}

p {
  @apply mb-4;
}

a {
  @apply theme-c2 transition-colors duration-300;
}

a:hover {
  @apply theme-c4;
}

* {
  box-sizing: border-box;
}

ul {
  @apply list-disc pt-3 pl-6;
}

.bgWrap {
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: -1;
}

/* MENU */

.mainNav {
  z-index: 1000;

  @apply h-14 fixed top-0 left-0 right-0 animated-slow flex items-center text-base theme-b4;
  @apply hidden lg:block;

  .Logo {
    height:22px;
  }

  &.floating {
    @apply theme-b1 shadow-md;

    .Logo path {
      fill: var(--theme-color-1);
    }
  }

  &.navHidden {
    top: -100px !important;
    opacity: 1;
  }

  .navBar {
    @apply flex h-full items-center font-normal p-0 m-0;

    li,
    li>div {
      @apply flex h-full items-center;
    }

    button,
    a {
      @apply h-full flex justify-start items-center px-6 animated theme-c4 whitespace-nowrap font-bold;

      svg {
        @apply w-4 h-4 theme-c2 ml-3;
      }
    }
    
    button:hover,
    a:hover,
    button.isOpen,
    a.isOpen,
    button.active,
    a.active {
      @apply theme-b2 theme-c1;

      svg {
        @apply theme-c1;
      }
    }

    .dropdown-panel {
      min-width: 255px;

      @apply absolute z-10 top-full left-0 animated shadow-xl;

      &-inner {
        padding-top:1px;

        @apply gap-0;

        a {
          border-color:var(--theme-color-2);
          background-color: rgba(255, 255, 255, 0.95);
          margin-bottom:1px;
          @apply py-2 theme-c4 border-l-8 w-full;

          &.active,
          &:hover {
            @apply theme-b2 theme-c1;
          }
        }

      }

      .dropdown-panel {
        top:-1px;
        right:calc(-100% - 1px);
        @apply left-auto;
      }
    }
  }

  .contact-btn {
    @apply h-full flex justify-start items-center px-6 animated theme-c1 theme-b2 flex-nowrap whitespace-nowrap font-medium;

    svg {
      @apply w-4 h-4 theme-c1 mr-3;
    }

    &:hover {
      @apply theme-b4 theme-c1; 

      svg {
        @apply theme-c1;
      }
    }
  }

  .btn-shine {
    @apply hidden xl:flex;
  }
}

/* MOBILE MENU */

.mainNavMobile {
  z-index: 100;
  @apply theme-b4 h-14 fixed top-0 left-0 right-0 px-6 animated-slow flex items-center text-base;

  &.navHidden {
    top: -100px !important;
    opacity: 1;
  }

  .Logo {
    height:22px;
    @apply transition-all duration-500 opacity-100;
  }

  &.floating {
    @apply theme-b4 shadow;

    .Logo path {
      fill: var(--theme-color-4);
    }

    .navBar {

      button,
      a {
        @apply theme-c1;
      }

      button:hover,
      a:hover,
      button.isOpen {
        @apply theme-b2 text-white;
      }

      .dropdown-panel {
        @apply theme-b2;

        &-inner {
          @apply gap-0;

          a {
            @apply py-2 text-white;

            &:hover {
              @apply theme-b4;
            }
          }

        }
      }


    }


  }

}

.burger {
  // position: fixed;
  // right: 25px;
  // z-index: 99999;
  // top: -75px;
  // // top: 25px;
  // @apply animated-slow flex;

  &.btn_menu {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 22px;
    padding-left: 0;
    padding-right: 0;
    border: none;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    transition: .5s ease;

    &:focus {
      outline: none;
    }

    .btn_menu__bars {
      display: block;
      position: relative;
      width: 50px;
      height: 3px;
      @apply theme-b1;
      transition: .5s;

      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        @apply theme-b1;
        transition: .5s;
      }

      &:before {
        transform: translate(0, -12px);
      }

      &:after {
        transform: translate(0, 12px);
      }
    }


  }

  &.menu_open {

    top: 17px;

    .btn_menu__bars {
      background-color: transparent;

      &:before {
        transform: rotate(45deg);
        background-color: var(--theme-color-1);
      }

      &:after {
        transform: rotate(-45deg);
        background-color: var(--theme-color-1);
      }
    }
  }
}

.isTop {
  .burger {
    top: 17px;

    // .btn_menu__bars {
    //   background-color: var(--theme-color-4);

    //   &:before,
    //   &:after {
    //     background-color: var(--theme-color-4);
    //   }
    // }

    // &.menu_open {
    //   .btn_menu__bars {
    //     background-color: transparent;
    //   }
    // }
  }
}

.mobileMenuShow {
  .burger {
    top: 17px;
  }
}

.mobileOffCanvas {
  height: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  transition: 0.5s;
  width: 100%;
  // -webkit-transform: translateX(0);
  // transform: translateX(0);
  // @apply flex justify-end block lg:hidden translate-x-full;
  @apply flex justify-end block w-full h-0 overflow-hidden;

  .mobileMenu {
    @apply theme-b4 shadow-xl z-20 h-full w-full;

    .mobileMenuContainer {
      @apply pt-20 flex h-full overflow-hidden items-center relative;

      .MobileLogo {
        @apply block absolute left-0 px-8 sm:px-0 sm:left-16 top-0  opacity-0 w-3/4 sm:w-auto;
      }

      .navBar {
        @apply m-0 p-0 list-none px-0 sm:px-8 py-8;

        a,
        a.active {
          @apply font-normal text-4xl theme-c1 mb-6 flex items-center relative pb-1;

          svg {
            @apply w-4 h-4 theme-c1 ml-3;
          }

          &::before {
            content: "";
            position: absolute;
            height: 1px;
            -webkit-transition: width 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
            transition: width 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
            @apply absolute bottom-0 right-0 w-0 theme-b2;
          }

          &:hover {
            &::before {
              right: initial;
              left: 0;
              width: 100%;
            }
          }
        }

        a.active {
          &::before {
            right: initial;
              left: 0;
              width: 100%;
          }
        }

        .dropdown-panel-inner {
          @apply pl-8 pb-6;

          a {
            @apply mb-2;
          }
        }
      }

    }

    .navBar {
      @apply my-10 px-4 sm:px-10;

      li {
        @apply -translate-x-96;
      }
    }

    .dropdown-toggle {
      @apply w-8;
      .dropdown-icon-down,
      .dropdown-icon-up {
        @apply text-white mt-2 w-4;
      }
    }

  }
}

// .mobileCanvasOverlay {
//   left: 0;
//   top: 0;
//   width: 100%;
//   position: fixed;
//   height: 100%;
//   z-index: 99997;
//   @apply theme-b1 opacity-50;
//   // @apply hidden lg:hidden #{!important};
//   @apply hidden;
// }

.mobileMenuOpened {

  .mobileOffCanvas {
    .mobileMenu {  
      .mobileMenuContainer {  
        .MobileLogo {
          @apply opacity-100 delay-300 duration-1000 transition-all top-16;
        }
      }
    }
  }

  .Logo {
    @apply opacity-0;
  }

  

  // .mobileCanvasOverlay {
  //   // @apply block lg:hidden #{!important};
  //   @apply block;
  // }

  .mobileOffCanvas {
    @apply w-full h-full;

    .mobileMenuContainer {
      // @apply overflow-y-auto;
    }

    .navBar {
      @apply my-10 px-10;

      li {
        @apply translate-x-0 transition-all duration-1000;
      }

      li:nth-of-type(1) {
        transition-delay: 200ms;
      }

      li:nth-of-type(2) {
        transition-delay: 300ms;
      }

      li:nth-of-type(3) {
        transition-delay: 400ms;
      }

      li:nth-of-type(4) {
        transition-delay: 500ms;
      }

      li:nth-of-type(5) {
        transition-delay: 600ms;
      }
    }

  }




}

/* GLOBAL */

.animated-slow {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.animated {
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

// .card {
//   @apply shadow h-full animated font-normal text-sm border rounded-lg theme-c4 theme-b1;

//   .image {
//     @apply p-6 bg-white rounded-t-lg;
//   }

//   .cardContent {
//     @apply p-6;
//   }

//   .card-btn {
//     @apply theme-b2 py-3 px-6 text-white text-xl animated inline-flex items-center;
      
//     &:hover {
//       @apply theme-b3 theme-c4;

//       path {
//         fill:var(--theme-color-4);
//       }
//     }
//   }

//   &:hover {
//     @apply shadow-2xl cursor-pointer;
//   }
// }

.btn {
  @apply py-2 px-6 animated text-sm shadow hover:shadow-xl theme-c1 theme-b4;

  &:hover {
    @apply theme-b1 theme-c4;
  }
}

.btn-light {
  @apply font-normal border theme-c4;
  border-color: var(--theme-color-4);
  background-color: transparent;

  &:hover {
    @apply theme-c4 theme-b1;
    background-color: white;
  }
}

.btn-big {
  @apply text-2xl font-normal;
}

.rowFooter {
  @apply bg-slate-800
}

h1, h2 {
  @apply text-5xl lg:text-7xl theme-c4 mb-8;
}

h3, h4 {
  @apply text-3xl lg:text-5xl theme-c4 mb-16 lg:mb-8;
}

h5, h6 {
  @apply text-xl lg:text-2xl theme-c4 mb-16 lg:mb-8;
}

.griglia {
  @apply block md:grid;
  & > div {
    @apply  mb-8 lg:mb-0;
  }
}

.link {
  @apply mt-3;
}


.container {
  @apply px-6;
}

.swiper {
  .swiper-slide {
    @apply flex flex-col items-center justify-center text-center;
    .caption {
      @apply mt-4;
    }
  }
}

/* PROGRESS BAR */

#nprogress {
  .bar {
    .peg {
      @apply hidden;
    }
  }
}

section {
  @apply py-20;

  h1, h2, h3, h4, h5, h6 {
    @apply text-xl md:text-3xl font-semibold;
  }
}


/* CUSTOM TEMPLATE */

h1, h2, h3, h4, h5, h6 {
  strong {
    @apply theme-c2 font-normal;
  }
}

.externalBtn {
  @apply inline-flex p-4 theme-b4 theme-c1 transition-all duration-300;
  svg {
    @apply w-5 ml-4;
    path {
      @apply  transition-all duration-500;
      fill: var(--theme-color-1);
    }
  }

  &:hover {
    @apply theme-b2 theme-c4;
    svg {
      path {
        fill: var(--theme-color-4);
      }
    }
  }
}

.backBtn {
  @apply inline-flex p-4 theme-b4 theme-c1 transition-all duration-300;
  svg {
    @apply w-5 mr-4;
    path {
      @apply  transition-all duration-500;
      fill: var(--theme-color-1);
    }
  }

  &:hover {
    @apply theme-b2 theme-c4;
    svg {
      path {
        fill: var(--theme-color-4);
      }
    }
  }
}

#riga-grigia {
  @apply theme-b3 theme-c1;
  h1, h2, h3, h4, h5, h6 {
    @apply relative theme-c1 font-normal;
    &::after {
      content:'';
      @apply block w-24 h-1 theme-b2 absolute -bottom-4 left-0;
    }
    strong {
      @apply theme-c2;
    }
  }

  .image {
    @apply flex justify-center h-full items-center;
  }

  .text {
    @apply flex justify-center h-full items-center;
  }
}

#riga-bianca {
  h1, h2, h3, h4, h5, h6 {
    @apply relative;
    &::after {
      content:'';
      @apply block w-24 h-1 theme-b2 absolute -bottom-8 left-0;
    }
    strong {
      @apply theme-c2;
    }
  }
}

#riga-verde {
  @apply theme-b2 theme-c1;

  h1, h2, h3, h4, h5, h6 {
    @apply relative theme-c1 font-normal;
    strong {
      @apply theme-c1;
    }
  }
}

main.homepage {

  section {
    @apply theme-b1;
  }

  #riga-cervello {
    @apply sm:h-screen flex flex-col md:flex-row items-center justify-between md:justify-start theme-b2 theme-c1 relative py-0 px-4 md:px-0;

    h1 {
      @apply theme-c1 text-2xl lg:text-5xl font-normal;
    }

    h2 {
      @apply theme-c1 text-xl lg:text-4xl font-normal mt-12;
    }

    #cervello {
      aspect-ratio: 1200 / 1037;
      @apply  order-1 h-auto z-10 relative right-4 mt-16 py-8 w-full sm:w-auto px-8 sm:absolute top-0 sm:order-2 sm:h-full sm:top-1/2 sm:max-w-lg xl:top-0 xl:right-0 sm:-mt-64 xl:mt-0 xl:max-w-none;
    }

    .cervello-components {
      @apply relative order-2 mb-8 sm:mb-0 sm:order-1 sm:absolute sm:bottom-24 sm:left-24 z-20 sm:w-1/2;
    }

    .externalBtn {    
      &:hover {
        @apply theme-b1 theme-c4;
        svg {
          path {
            fill: var(--theme-color-4);
          }
        }
      }
    }
  }


}

#riga-form {
  @apply theme-b2 theme-c1;

  h1, h2, h3, h4, h5, h6 {
    @apply relative theme-c1 font-normal text-center;
    strong {
      @apply theme-c1;
    }
  }
}

.form-contact {
  @apply max-w-2xl mx-auto;
  label {
    @apply theme-c1;

    a {
      @apply theme-c4 underline;
    }
  }
  input[type=text] {
    @apply appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none p-2 mt-4;
    // border-bottom:1px solid var(--theme-color-1);
    background:#d3f7b0;
  }
  textarea {
    @apply appearance-none bg-transparent border-none w-full h-32 text-gray-700 mr-3 py-1 leading-tight focus:outline-none mt-4;
    // border:1px solid var(--theme-color-1);
    background:#d3f7b0;
  }

  .externalBtn {
    @apply theme-b4;
  
    &:hover {
      @apply theme-b1;
    }
  }
}

.contact-form {
  a { 
    @apply theme-c4;
  }

  .sendLoader {
    @apply absolute w-full h-full theme-b2 z-10 flex flex-col justify-center items-center;
  }
}

#footer {
  @apply theme-b4 py-12 px-6 theme-c1;

  section {
    @apply py-0;
  }

  h5 {
    @apply theme-c1 text-xl m-0 mb-2;
  }

  a {
    @apply theme-c2;
  }
}

#riga-image-intro {

  h1, h2, h3, h4, h5, h6 {
    @apply theme-c1 font-normal md:text-5xl;
    strong {
      @apply theme-c2;
    }
  }

  @apply py-0 theme-b4;

  #image-intro {
    @apply relative;

    .intro-image-component {
      @apply h-screen relative;

      .image {
        position: relative;
        opacity: 0.25;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @apply h-full md:h-screen;
      }

      .caption {
        @apply absolute bottom-4 left-4 md:bottom-16 md:left-16 pt-24 theme-c1 w-full md:w-1/2 text-xl px-8 md:px-0 pb-24 md:pb-0;
      }
    }
  }
}

main.innerpage {
  section {
    @apply theme-b1;
  }
}

#page-146 {

  h3 {
    @apply theme-c2;
  }

  #agenzia-titolone {
    @apply flex items-center justify-center;
  
    h1,h2,h3,h4,h5,h6 {
      font-size:120px;
      @apply font-bold theme-c3;
    }
  }

  #section-1663570692, #section-1663572065 {
    .griglia {
      @apply flex flex-col md:grid;
    }
    .col-span-1:nth-child(1) {
      @apply order-2 md:order-1;
    }
    .col-span-1:nth-child(2) {
      @apply order-1 md:order-2;
    }
  }
}

#page-146 {
  .col-span-1 ~ .col-span-1 {
    @apply order-1 md:order-none;
  }
}


#sezione-intro {
  @apply h-screen flex items-center;

  .text {
    @apply h-full flex items-center;

    h1, h2, h3, h4, h5, h6 {
      @apply relative theme-c4 font-normal uppercase;
      &::after {
        content:'';
        @apply block w-24 h-1 theme-b2 absolute -bottom-4 left-0;
      }
      strong {
        @apply theme-c2;
      }
    }
  }
}

#servizi-cards {
  .card {
    @apply flex griglia grid-cols-2 items-center py-12;

    .cardContent {
      @apply text-left;
    }
    
    
  }
  
  & > div:nth-child(even) {
    .card {
      .image {
        @apply order-1;
      }
      .cardContent {
        @apply order-2;
      }
    }
  }

  & > div:nth-child(odd) {
    .card {
      .image {
        @apply order-2;
      }
      .cardContent {
        @apply order-1;
      }
    }
  }
  
}

#sezione-mappa {
  @apply m-0 p-0 theme-b4 pt-14;

  #mappa {
    iframe {
      height: 500px;
      width:100%;
      filter: brightness( 113% ) contrast( 89% ) saturate( 0% ) blur( 0px ) hue-rotate( 289deg );
    }
  }
}

#sezione-contatti {
  h1 {
    @apply relative theme-c4 font-normal;
    &::after {
      content:'';
      @apply block w-24 h-1 theme-b2 absolute -bottom-4 left-0;
    }
    strong {
      @apply theme-c2;
    }
  }
  h1 {
    @apply text-xl md:text-5xl
  }

  h3 {
    @apply text-base mt-12;
  }

  .griglia {
    @apply block md:grid;
  }
}

#portfolio-riga {
  @apply pt-24;
  h1 {
    @apply relative font-normal text-6xl;
    &::after {
      content:'';
      @apply block w-24 h-1 theme-b2 absolute -bottom-4 left-0;
    }
    strong {
      @apply theme-c2;
    }
  }
}

#portfolio-collection {
  .card {
    @apply mb-64;
  }

  .cardContent {
    @apply griglia grid-cols-4 relative gap-16;

    .image {
      @apply col-span-4 sm:col-span-2 lg:col-span-3 p-0 transition-all duration-300 relative order-1 md:order-2;

      .box-overlay {
        background:rgba(0, 0, 0, 0.5);
        @apply opacity-0 transition-opacity duration-500 absolute top-0 left-0 w-full h-full flex items-center justify-center;

        & > div {
          @apply flex text-2xl theme-c1;

          svg {
            @apply w-8 h-8;

            path {
              fill:var(--theme-color-1);
            }
          }
        }
      }

      &:hover {
        @apply p-0;

        img {
          @apply transition-all duration-300 blur-sm;
        }

        .box-overlay {
          @apply opacity-100;
        }
      }

    }

    .caption {
      @apply flex flex-col justify-between col-span-4 lg:col-span-1 sm:col-span-2 order-2 md:order-1;

      & > div {
        @apply flex flex-col;
      }

      a.box {
        @apply relative theme-b4 flex h-full aspect-square items-center justify-center text-center text-2xl p-8 theme-c1 font-bold;

        span {
          @apply absolute top-4 left-4 font-light;
        }
        
        
        &:hover {
          @apply theme-b2 theme-c4;
        }

      }



      .externalBtn {
        @apply justify-start p-0 bg-transparent theme-c2;

        svg {
          @apply ml-2;
        }

        path {
          fill:var(--theme-color-2);
        }

        &:hover {
          @apply theme-c4;

          path {
            fill:var(--theme-color-4);
          }
        }
      }

      .dt {
        @apply flex items-center mt-2;
        // &::before {
        //   content:'';
        //   height:1px;
        //   @apply block theme-b4 left-0 top-1/2 w-auto flex-1;
        // }
        p {
          @apply p-0 m-0;
        }
      }
    }
  }
}

#project {
  @apply py-16;

  h1 {
    @apply relative theme-c4 font-normal text-4xl uppercase;
    &::after {
      content:'';
      @apply block w-24 h-1 theme-b2 absolute -bottom-4 left-0;
    }
    strong {
      @apply theme-c2;
    }
  }

  .dt {
    @apply  mb-16;
  }

  .image {
    @apply mb-16;
  }

  .back {
    @apply mt-16;
  }
}
