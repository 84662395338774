.st0 {
  fill: #f2ae76;
}
.st1 {
  fill: #8fd2ee;
}
.st2 {
  fill: #e96551;
}
.st3 {
  fill: #763630;
}
.st4 {
  fill: #397f95;
}
.st5 {
  fill: #f4a59f;
}
.st6 {
  fill: #ef8c9e;
}
.st7 {
  fill: #3f4177;
}
.st8 {
  fill: #386cb3;
}
.st9 {
  fill: #fabe55;
}
.st10 {
  fill: #5b3d57;
}
.st11 {
  fill: #53c1ea;
}
.st12 {
  fill: #4babda;
}
.st13 {
  fill: #459ad5;
}
.st14 {
  fill: #b24c54;
}
.st15 {
  fill: #a44f72;
}
.st16 {
  fill: #ee7b6e;
}
.st17 {
  fill: #ef8c86;
}
.st18 {
  fill: #c283b8;
}
.st19 {
  fill: #4a55a1;
}
.st20 {
  fill: #5d5e60;
}
.st21 {
  fill: #507ec0;
}
.st22 {
  fill: #6c4d91;
}
.st23 {
  fill: #7c569f;
}
.st24 {
  fill: #234c9b;
}
.st25 {
  fill: #706caf;
}
.st26 {
  fill: #ab5e9e;
}
.st27 {
  fill: #9561a4;
}
.st28 {
  fill: #e96a97;
}
.st29 {
  fill: #d4619d;
}
.st0 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 0.1s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st1 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 0.3s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st2 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 0.6s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st3 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 0.8s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st4 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 1s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st5 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 1.2s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st6 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 1.4s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st7 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 1.6s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st8 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 1.8s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st9 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 2s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st10 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 2.2s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st11 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 2.4s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st12 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 2.6s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st13 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 2.8s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st14 {
  animation-name: pweek;
  animation-delay: 3s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st15 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 1.6s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st16 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 1.7s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st17 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 1.8s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st18 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 1.9s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st19 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 2s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st20 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 2.1s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st21 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 2.2s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st22 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 2.3s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st23 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 2.4s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st24 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 2.5s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st25 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 2.6s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st26 {
  animation-name: pweek;
  animation-duration: 3s, 5s;
  animation-delay: 2.7s, 0s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st27 {
  animation-name: pweek;
  animation-delay: 2.8s, 0s;
  animation-duration: 3s, 5s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st28 {
  animation-name: pweek;
  animation-delay: 2.9s, 0s;
  animation-duration: 3s, 5s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}
.st29 {
  animation-name: pweek;
  animation-delay: 3s, 0s;
  animation-duration: 3s, 5s;
  animation-iteration-count: infinite, 1;
  animation-timing-function: linear, linear;
}

@keyframes pweek {
  0% {
    fill: #f2ae76;
  }
  20% {
    fill: #f11e4a;
  }
  40% {
    fill: #f8a527;
  }
  60% {
    fill: #266d7f;
  }
  80% {
    fill: #82a;
  }
  100% {
    fill: #0057b8;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



